.table-box {
    width: 100%;
    display: flex;
    margin-right: 0px;
}

.token-image {
    width: 40px;
    height: 40px;
}

#allowance-data {
    font-size: 16px;
}

#revoke-button {
    color: black;
    font-weight: bold;
    font-size: 14px;
    background-color: white;
    border: 2px solid black;
}

#revoke-button:hover {
    color: black;
    background-color: white;
    border: 2px solid red;
}

a {
    color: black;
    font-size: larger;
}

a:visited {
    color: black;
}

@media only screen and (max-width: 600px) {
    a {
        color: black;
        font-size: medium;
    }

    .token-image {
        width: 30px;
        height: 30px;
    }
    
}
