#module-header {
    width: 100%;
    display: flex;
    margin-right: 20px;
    color: white;
    padding-right: 20px;
}

#module-header #connect-wallet {
   width: 150px;
   border-radius: 20px;
   font-weight: bolder;
   font-size: medium;
   color: black;
   background-color: white;
   display: flex;
   justify-content: center;
   margin-right: 5px;
   margin-left: 20px;
   margin-top: 10px;
   margin-bottom: 5px;
   padding-top: 8px;
   padding-bottom: 8px;
   box-shadow: 0px 0px 0px 2px white;
}

#module-header #connect-wallet:hover {
   color: black;
   background-color: white;
   border: 1px solid black;
   
}

#chain {
    font-size: small;
    font-weight: bold;
    text-align: center;
    margin-left: 15px;
}

#network-box {
    display: flex;
    margin-right: 20px;
    margin-left: auto;
    height: 50px;
    width:  50px;
}

#network-box img {
    width: 60px;
    height: 60px;
}

#network-button {
    background-color: black;
    border: none;
}

@media only screen and (max-width: 600px) {
    #network-box {
        display: flex;
        margin-right: 5px;
        margin-left: auto;
        height: 50px;
        width:  50px;
    }

    #network-box img {
        width: 50px;
        height: 50px;
    }

    #module-header #connect-wallet {
        width: 100px;
        border-radius: 20px;
        font-weight: bolder;
        font-size: small;
        color: black;
        background-color: white;
        display: flex;
        justify-content: center;
        margin-right: 5px;
        margin-left: 10px;
        margin-top: 10px;
        margin-bottom: 10px;
        padding-top: 8px;
        padding-bottom: 8px;
        box-shadow: 0px 0px 0px 2px white;
     }

     #chain {
        display: none;
    }
}