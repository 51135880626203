#header {
    width: 100%;
    display: flex;
    padding-right: 20px;
    padding-left: 20px;
    padding-top: 20px;
}

img {
    width: 150px;
    height: 55px;
}

@media only screen and (min-width: 600px) {
    img {
        width: 200px;
        height: 70px;
    }
}

#logo:hover {
    transform: rotate(2deg);
    opacity: 0.8;
}

