html, body {
  margin: 0px;
  background-color: black;
  padding: 0px;
  max-width: 100%;
  height: 100%;
  overflow-x: hidden;
  font-family: 'Cutive Mono', monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Asar';
}

button:hover {
  cursor: pointer;
}
