

h1 {
    color: white;
    font-weight:  bolder;
    width: 100%;
    text-align: center;
    font-size: 40px;
}

h3 {
    color: white;
    text-align: center;
    font-size: 20px;
}

p {
    color: white;
    text-align: center;
    font-weight: bold;
}

image {
    display: flex;
    margin: auto
}

.body {
    width: 100%;
    display: flex;
    margin: auto
}

#wait {
    width: 100%;
    margin-top: 0px;
    margin-bottom: auto;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    align-items: center;
    height: 500px;
}

#revoke-all {
    width: 100%;
    display: flex;
    margin: auto;
    padding: 20px;  
}

#revoke-all button {
    display: flex;
    margin: auto;
    font-size: xx-large;
    font-family: monospace;
    font-weight: bold;
    padding-left: 20px;
    padding-right: 20px;
    color: black;
    background-color: white;
    border: 0px solid black;
}

#revoke-all button:hover {
    color: white;
    background-color: black;
    border: 1px solid white;
}

#joke {
    margin-top: 5px;
    margin-bottom: auto;
}

@media only screen and (max-width: 600px) {
    #revoke-all button {
        display: flex;
        margin: auto;
        font-size: large;
        font-weight: bold;
        padding-left: 20px;
        padding-right: 20px;
        color: black;
        color: black;
        background-color: white;
        border: 0px solid black;
    }
    
    #revoke-all button:hover {
        color: white;
        background-color: red;
        border: 1px solid white;
    }

    #extra {
        height: 100px;
    }

    h1 {
        font-size: 30px;
    }
    
    h3 {
        font-size: 15px;
    }
}
